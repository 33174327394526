/*                                                                              */
/*                                                  */

export const browser = [
  "Chrome",
  "Edge",
  "Firefox",
  "MobileSafari",
  "Opera",
  "Safari",
  "App_WebView",
];

export const deviceTypes = ["desktop", "mobile", "tablet"];

export const pageCluster = [
  "AftersalesOrders",
  "AftersalesReturns",
  "Artikeldetailseite",
  "Artikelkombiseite",
  "BBS",
  "Checkout",
  "ConnectFeedback",
  "ContactComplaint",
  "Einstiegsseite",
  "Login",
  "MYA_Balance",
  "MYA_Dashboard",
  "MYA_Invoices",
  "MYA_Orders",
  "MYA_Profile",
  "MYA_Profile_Data",
  "MYA_Profile_Permissions",
  "MYA_UP",
  "Paymentinfo",
  "Produktliste",
  "Service",
  "Storefront",
  "Suchergebnisseite",
  "User",
  "Warenkorb",
  "Wishlist",
];

/*                                             */
export const rumArray = [
  "dnsTime",
  "domContentLoaded",
  "domContentLoadedStart",
  "domTime",
  "networkTime",
  "rum_complete",
  "rum_domComplete",
  "sslHandshakeTime",
  "timeToFirstByte",
  "timeToFirstPaint",
];

/*                                             */
export const trackingPIArray = [
  "rum_deviceType",
  "rum_domComplete",
  "rum_innerHeight",
  "rum_innerWidth",
  "rum_LCPElement",
  "rum_LCPURL",
  "rum_LCPTimeToFirstByte",
  "rum_LCPResourceLoadDelay",
  "rum_LCPResourceLoadDuration",
  "rum_LCPElementRenderDelay",
  "rum_FirstContentfulPaint",
  "rum_LargestContentfulPaint",
  "rum_FirstInputDelay",
  /*                                                                                         */
];

/**
 *
 *
 *
 */
export const trackingEventArray = [];

/*                                               */
export const userTimingArray = [
  "rum_CumulativeLayoutShift",
  "rum_LargestContentfulPaint",
  "rum_FirstInputDelay",
  "rum_FirstContentfulPaint",
  "rum_InteractionToNextPaint",
  "rum_entryPage",
  "rum_ProductDetailViewVisuallyComplete",
  "rum_reco_orderOverview",
  "rum_RecoDetailViewComplementary",
  "rum_RecoDetailViewProductline",
  "rum_RecoDetailViewAlternative",
  "rum_RecoProductline",
  "rum_reco_wishlist",
];
